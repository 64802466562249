// Import Images
import background from '../../../assets/Images/image-skilsmisse.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Page4data = {
  pageName: 'Page4',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_4',
    pageTitle: 'Sampension',
    pageDescription: 'Sampensions web site',
    bannerImage: background,
    mobile: null,
    // mobile: BannerImageMobile,
    bannerContent:
      '<h1>Få overblik over din pension</h1><p class="intro">Savner du overblik over din pension - hvad indeholder ordningen egentlig, hvor stor er min opsparing, hvor meget indbetaler jeg og sparer jeg nok op? Her er tre indgange til at få overblik.</p>',
    infoCards: null,
    desktopTools: null,
    backButton: true,
    backButtonText: 'Tilbage til Arbejdsgiver',
    backButtonUrl: '/page2',
  },
  pageContent: [
    {
      componentName: 'CollapsibleTextArea',
      componentData: {
        openText: 'Læs mere',
        closeText: 'Læs mindre',
        text: [
          {
            componentName: 'HtmlRender',
            componentData: {
              html:
                '<h2>Header Text</h2><p>Eget nunc scelerisque viverra mauris in aliquam. Massa ultricies mi quis hendrerit. Enim nulla aliquet porttitor lacus. Neque laoreet suspendisse interdum consectetur libero id. Sapien pellentesque habitant morbi tristique senectus et netus. Aenean euismod elementum nisi quis eleifend. Eget duis at tellus at urna condimentum. Orci porta non pulvinar neque laoreet suspendisse. Pellentesque nec nam aliquam sem et. Elit duis tristique sollicitudin nibh sit amet commodo. Mi proin sed libero enim sed faucibus turpis in.</p>',
            },
          },
        ],
      },
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Auctor urna nunc id cursus metus. Mollis nunc sed id semper risus. Adipiscing at in tellus integer feugiat. Mauris cursus mattis molestie a iaculis. Magna ac placerat vestibulum lectus mauris ultrices eros. Sed cras ornare arcu dui vivamus arcu felis bibendum. Nibh praesent tristique magna sit amet purus gravida quis. In ornare quam viverra orci sagittis eu volutpat. Id donec ultrices tincidunt arcu non. In aliquam sem fringilla ut. In ante metus dictum at tempor commodo ullamcorper a. Turpis in eu mi bibendum. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Non nisi est sit amet facilisis magna etiam tempor orci.</p>',
          },
        },
      ],
    },
    {
      componentName: 'CollapseableFAQ',
      componentData: {
        header: 'FAQ / Pension og skilsmisse',
        faqs: [
          {
            id: '1',
            header: 'Hvad sker der med mine pensioner, hvis jeg skal skilles?',
            text:
              'Reglerne ved separation og skilsmisse er, at du altid kan beholde "rimelige pensioner".<br /><br /><br /><br />Almindelige arbejdsmarkedspensioner er altid rimelige, eftersom du kun betaler det beløb, der fx er fastsat i din lønoverenskomst. Som hovedregel skal værdien af din pension i Sampension ikke deles. Men din ægtefælle skal måske have en kompensation.',
          },
          {
            id: '2',
            header: 'Kan jeg beholde mine pensioner, hvis jeg skal skilles?',
            text: 'text 2',
          },
          {
            id: '3',
            header: 'Hvad sker der med ægtefællepensionen ved skilsmisse?',
            text: 'text 3',
          },
        ],
      },
    },
    {
      componentName: 'TextArea',
      componentData: {
        color: ColorGuide.aquaLight,
      },
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>Ikke alle er dækket</h2><p>En mindre gruppe kunder er ikek dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob elle r under andre særlige nsættelsesforhold.</p>',
          },
        },
      ],
    },
  ],
};

// Exports
export default Page4data;
