// Import Node Modules
import React from 'react';

// renderPage
import { renderPage } from '../template/RenderPage';

// TempData
import Page4data from '../mocks/pages/PageData/page4';

// Class
const Page4 = () => <div>{renderPage(Page4data)}</div>;

export default Page4;
